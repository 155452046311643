import React from "react";
import Select, { components } from "react-select";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const SelectComponent = ({
  data,
  label,
  defaultValue,
  name,
  errors,
  control,
  onKeyDown,
  id,
  isDisabled,
  style,
}) => {
  const Menu = (props) => {
    const { t } = useTranslation();
    return (
      <components.Menu {...props} className="select-menu-wrapper">
        {props.selectProps.fetchingData ? (
          <span className="fetching">Fetching data...</span>
        ) : (
          <>
            <div className="select-menu-label">
              {id === "add-article"
                ? t("Category")
                : id === "article-status"
                ? t("Article Status")
                : t("Tags")}
            </div>
            <div>{props.children}</div>
          </>
        )}
      </components.Menu>
    );
  };

  const Option = (props) => {
    return (
      <components.Option {...props} className="select-option">
        <div className="radio-btn-label-wrapper">
          <input
            type="radio"
            checked={props.isSelected}
            onChange={() => null}
            className="select-option-radio"
          />

          <div className="select-option-label">{props.label}</div>
        </div>
      </components.Option>
    );
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? (
          <KeyboardArrowUpIcon />
        ) : (
          <KeyboardArrowDownIcon />
        )}
      </components.DropdownIndicator>
    );
  };
  const styles = {
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "all .2s ease",
      transform: state.isFocused ? "rotate(180deg)" : null,
    }),
    control: (base, state) => ({
      ...base,
      border: state.isFocused
        ? "1px solid #ebebeb"
        : Object?.keys(errors)?.includes(name)
        ? "1px solid red"
        : "1px solid #ebebeb",
      borderRadius: "8px",
      height: "50px",
      backgroundColor: "white",
      borderbottom: state.isFocused ? "unset" : "1px solid #ebebeb",
      boxShadow: state.isFocused ? "0px " : "0px",
      "&:hover": {
        ...styles,
      },
    }),
    menu: ({ width, ...css }) => ({
      ...css,
      width: "100%",
      boxShadow: "0px",
      borderRadius: "8px",
      border: "1px solid #ebebeb",
    }),
    option: (provided, state) => ({
      ...provided,

      fontWeight: "normal",
      color: "#78787A",
      width: "100%",
      backgroundColor: "white",
      fontSize: "12px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#78787A",
      width: "100%",
      fontSize: "12px",
    }),
  };

  return (
    <div className="title-select-wrapper" style={style}>
      <span style={{ marginTop: "10px" }}>{label}</span>
      <Controller
        control={control}
        options={data}
        name={name}
        render={({ field: { onChange, value, ref } }) => {
          return (
            <>
              <Select
                options={data}
                name={name}
                components={{ Menu, Option, DropdownIndicator }}
                styles={styles}
                value={data?.filter((c) => value?.includes(c?.value))}
                onChange={(val) => onChange(val.value)}
                onKeyDown={onKeyDown}
                placeholder={defaultValue}
                isDisabled={isDisabled}
              />

              {Object.keys(errors).map((fieldName, i) => {
                return (
                  fieldName === name && (
                    <span style={{ color: "red" }} key={i}>
                      {errors[fieldName]?.message}{" "}
                    </span>
                  )
                );
              })}
            </>
          );
        }}
      />
    </div>
  );
};

export default SelectComponent;
